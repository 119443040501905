<template>
    <modal ref="modalPazSalvo" titulo="Paz y salvo" class="modal-paz-salvo">
        <div class="row">
            <div class="col-12 col-md-6 col-lg text-center">
                <div class="row f-15">
                    <div class="col-12 col-md-6 mb-0 mb-lg-2">
                        <p class="f-500"> Paz y salvo hasta </p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2">
                        <p class="f-100">{{ formatearFecha(pazSalvo.fecha_pago_mes,'MMMM YYYY') }}</p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2">
                        <p class="f-500">Fecha y hora de registro </p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2">
                        <p class="f-100">{{ formatearFecha(pazSalvo.created_at,'DD MMM yyyy h:m a') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            pazSalvo: {}
        }
    },
    methods:{
        toggle(data){
            this.pazSalvo = data
            this.$refs.modalPazSalvo.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-paz-salvo{
    .modal-sm{
        min-width: 390px !important;
    }
}
</style>